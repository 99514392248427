<mat-form-field class="commentBox">
    <mat-label class="flex-row flex-justify-space-between"><span>{{ comment.autore }} | {{ comment.dataCreazione | date : 'dd/MM/yyyy HH:mm' : 'UTC' }}</span
		>
	</mat-label>
	<textarea
		matInput
		readonly
		[value]="comment.descrizione"
		cdkTextareaAutosize
		#autosize="cdkTextareaAutosize"
		cdkAutosizeMinRows="1"
		cdkAutosizeMaxRows="5"
	></textarea>
	<span *ngIf="comment.idContenutoAllegato">
		<button mat-icon-button type="button" (click)="showAttachment(comment)">
			<mat-icon class="cursor-pointer" color="accent">file_open</mat-icon>
			<span> Apri allegato </span>
        </button>
        </span>
</mat-form-field>

<span class="flex-row flex-justify-space-between flex-align-end header-bubble">
	<span
		id="showMoreLabel"
		class="flex-row flex-justify-space-between flex-align-end"
		(click)="showHistory(comment)"
		*ngIf="areActionsEnabled && canShowMore()"
	>
		<mat-icon style="transform: rotate(-135deg)">undo</mat-icon>{{ showMoreLabel }}
		<!-- <mat-icon>subdirectory_arrow_right</mat-icon>{{ showMoreLabel }} -->
	</span>

<span class="flex-row flex-align-end" [dottnetSigninDirective]="permissionDialogTitle" id="replyLabel" (click)="showCommentBox(comment, indentLevel)" *ngIf="areActionsEnabled && indentLevel < maxNumReplies && canReply()">{{ replyLabel }}</span
	>
</span>

<!-- <span id="showMoreLabel" (click)="showHistory(comment)" *ngIf="areActionsEnabled && canShowMore()">
	<mat-icon>subdirectory_arrow_right</mat-icon>{{ showMoreLabel }}
</span> -->

<ng-template dottnetWidgetInject></ng-template>
