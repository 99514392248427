<div id="historyContainer" class="flex-col">
    <div id="history" class="flex-col">
        <!-- slice pipe makes possible to show only part of the array from inside the template -->
        <!-- isLast tells if the comment-bubble is the last one -->
        <dottnet-comment-bubble *ngFor="
				let comment of totalComments | slice : 0 : commentsShown;
				index as index;
				last as isLast;
				trackBy: commentTrackBy
			" [id]="'comment-' + comment.idContenuto" [comment]="comment" [replies$]="getCommentReplies(comment)" [indentLevel]="indentLevel" [areActionsEnabled]="isLast && areThereMoreComments() ? false : true" [ngClass]="{ 'last-comment-fade': isLast && areThereMoreComments() }"
            [ngStyle]="{
				'padding-left': indentLevel === 0 ? 0 : 1 + 'rem',

			}" (showReplyBoxEmitter)="showReplyBox($event)" (loadHistoryEmitter)="emitLoadCommentReplies($event)" (submitCommentEmitter)="emitSubmitComment($event)">
        </dottnet-comment-bubble>
    </div>
    <div class="history-toolbar flex-row flex-justify-center flex-align-center" *ngIf="totalComments?.length > commentsShown">
        <button mat-stroked-button class="flex-row flex-justify-center" color="accent" (click)="showMoreComments()" *ngIf="indentLevel < 1">
			{{ showMoreLabel }}
		</button>
        <a *ngIf="indentLevel >= 1" mat-icon-button class="flex-row flex-justify-center" (click)="showMoreComments()">
			{{ showMoreLabel }}
		</a>
    </div>
</div>
