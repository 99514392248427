<div [ngClass]="noBanner ? 'no-banner' : ''">
    <dottnet-content-view *ngIf="contentError?.Code > 0">
        <dottnet-message-in-page detail [title_message]="title_message" [message]="getErrorMessage(contentError)" class="flex-fill" />
        <dottnet-content-visualizer detail [title]="'dottnet.article.related' | translate" [contents$]="articleRelated$" [visualizationType]="ContentVisualizationType.StandardCard" class="flex-fill" [more]="false" />
        <dottnet-content-visualizer side [title]="'dottnet.article.interested' | translate" [contents$]="articleInteresting$" [visualizationType]="ContentVisualizationType.NoImageCardHome" class="flex-fill" [more]="false" />

    </dottnet-content-view>

    <dottnet-content-view *ngIf="!contentError?.Code">
        <!-- <dottnet-article-viewer [user$]="user$" [showVideoAds]="showAdv" [article$]="currentContent$" class="flex-fill" detail></dottnet-article-viewer> -->
        <dottnet-article-viewer [paymentIntent$]="paymentIntent$" [paymentSuccess$]="paymentSuccess$" [sessionId$]="sessionId$" [premiumModules$]="premiumModules$" [user$]="user$" [discountTypeList$]="discountTypeList$" [tokenError$]="tokenError$" [coupon$]="coupon$"
            [myPremium$]="myPremium$" [showVideoAds]="true" [article$]="currentContent$" class="flex-fill" detail [premiumUser]="premiumUser" (submitCheckTokenForm)="checkToken($event)" (submitSubscription)="setAbbonamento($event)" (submitUpdateSubscription)="updateSubscription($event)"
            (submitLoadUserPremium)="loadUserPremium($event)" (submitIntentSubscription)="intentSubscription($event)" (submitConfirmCardPayment)="confirmCardPayment($event)" [poll$]="poll$" (submitPollEmitter)="submitPoll($event)" (undoPollEmitter)="undoPoll()"
        />
        <dottnet-social detail [url]="shareLink" />
        <dottnet-comments detail [comments$]="comments$" (submitCommentEmitter)="submitComment($event)" (loadCommentRepliesEmitter)="loadCommentReplies($event)" (uploadTemporaryAttachmentEmitter)="uploadTemporaryAttachment($event)" (showAttachmentEmitter)="showAttachment($event)"
        />

        <dottnet-content-visualizer side [title]="'dottnet.article.interested' | translate" [contents$]="articleInteresting$" [visualizationType]="ContentVisualizationType.NoImageCardHome" class="flex-fill" [more]="false" />
        <dottnet-content-visualizer detail [title]="'dottnet.article.related' | translate" [contents$]="articleRelated$" [visualizationType]="ContentVisualizationType.StandardCard" class="flex-fill" [more]="false" />
    </dottnet-content-view>
</div>