<form [formGroup]="commentForm" (ngSubmit)="emitSubmitComment()" class="full-width flex-col flex-justify-center flex-align-end reply-container" [ngStyle]="{
		'padding-left': indentLevel === 0 ? 0 : '1rem',
		'margin-top': indentLevel === 0 ? 0 : '1rem'
	}">
    <div class="full-width flex-row flex-justify-center flex-align-center">
        <mat-form-field id="commentBox" class="full-width" [ngStyle]="{ 'margin-bottom': isAttachmentMenuVisible ? 0 : '1rem' }">
            <mat-label>{{ labelText }}</mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [formControl]="commentText" [errorStateMatcher]="matcher"></textarea>
            <mat-error>{{ errorMessage }}</mat-error>
            <button mat-icon-button type="button" matSuffix (click)="showAttachmentMenu()">
				<mat-icon id="attachmentClipIcon" class="cursor-pointer" color="accent">{{
					isAttachmentMenuVisible ? 'close' : 'attachment'
				}}</mat-icon>
			</button>
        </mat-form-field>
        <button mat-icon-button type="submit">
			<mat-icon color="accent">send</mat-icon>
		</button>
    </div>
    <dottnet-comment-upload-actions [@toolbarSlideIn]="isAttachmentMenuVisible" (@toolbarSlideIn.start)="$event.element.style.display = 'block'" (@toolbarSlideIn.done)="$event.element.style.display = $event.toState ? 'block' : 'none'" (temporaryAttachmentEmitter)="emitTemporaryAttachment($event)"
        (attachmentTypeEmitter)="saveAttachmentType($event)">
    </dottnet-comment-upload-actions>
</form>
