import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// No provided in cause it is provided only in CommentsModule
// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class CommentsService {
	// Emits when a comment-box resets the temporary attachment to a comment not yet submitted
	resetAttachmentSubject: Subject<boolean> = new Subject();

	// Emits when uploading a new attachment in a comment not yet submitted
	uploadTemporaryAttachmentSubject: Subject<File> = new Subject();

	// Emits when clicking on a comment's attachment
	showAttachmentSubject: Subject<{
		idAttachment: number;
		templateAttachment: string;
	}> = new Subject();

	constructor() {}

	resetAttachment() {
		this.resetAttachmentSubject.next(true);
	}

	onResetAttachment(): Subject<boolean> {
		return this.resetAttachmentSubject;
	}

	uploadTemporaryAttachment(file: File) {
		this.uploadTemporaryAttachmentSubject.next(file);
	}

	onUploadTemporaryAttachment(): Subject<File> {
		return this.uploadTemporaryAttachmentSubject;
	}

	showAttachment(idAttachment: number, templateAttachment: string) {
		this.showAttachmentSubject.next({ idAttachment, templateAttachment });
	}

	onShowAttachment(): Subject<{ idAttachment: number; templateAttachment: string }> {
		return this.showAttachmentSubject;
	}
}

