import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentRef,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChildren,
	ViewContainerRef,
	forwardRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DNComment } from '../../../domain/content/content.model';
import { selectCommentsByParentId } from '../../../domain/content/content.selectors';
import { CommentBoxComponent } from '../comment-box/comment-box.component';
import { CreateCommentDTO } from '../comments.component';
import { CommentBubbleComponent } from './comment-bubble/comment-bubble.component';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgClass, NgStyle, NgIf, SlicePipe } from '@angular/common';

const COMMENTS_PER_LOAD = 5;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-comment-history',
    templateUrl: 'comment-history.component.html',
    styleUrls: ['comment-history.component.scss'],
    standalone: true,
    imports: [NgFor, forwardRef(() => CommentBubbleComponent), NgClass, NgStyle, NgIf, MatButtonModule, SlicePipe]
})
export class CommentHistoryComponent implements OnInit, OnDestroy {
	@Input() comments$: Observable<DNComment[]> = new Observable();

	@Output() readonly submitCommentEmitter: EventEmitter<CreateCommentDTO> = new EventEmitter();
	@Output() readonly loadCommentRepliesEmitter: EventEmitter<DNComment> = new EventEmitter();

	@ViewChildren(CommentBubbleComponent) commentBubbleList!: QueryList<CommentBubbleComponent>;

	// Comments extracted from the observable. Used like this to get total length in the template
	totalComments: DNComment[] = [];

	// UI vars
	// Comments loading gap
	commentsShown: number = COMMENTS_PER_LOAD;
	indentLevel: number = 0;
	showMoreLabel: string = this.ts.instant('dottnet.shared.moreContent');

	// Close previous commentBox before opening another one
	lastCommentBoxViewRef: ViewContainerRef = undefined;
	lastCommentBoxRef: ComponentRef<CommentBoxComponent> = undefined;

	// Subs
	totalCommentsSub: Subscription;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private ts: TranslateService,
		private store: Store,
		private elementRef: ElementRef
	) {}

	ngOnInit(): void {
		this.totalCommentsSub = this.comments$.subscribe((comments) => {
			this.totalComments = comments;
			this.changeDetector.detectChanges();
		});
	}

	ngOnDestroy(): void {
		if (this.totalCommentsSub) this.totalCommentsSub.unsubscribe();
	}

	// Show a commentBox and hide the last one if already present
	showReplyBox(showReplyBoxData: {
		viewRef: ViewContainerRef;
		compRef: ComponentRef<CommentBoxComponent>;
	}) {
		const { viewRef, compRef } = showReplyBoxData;

		if (
			this.lastCommentBoxViewRef?.indexOf(this.lastCommentBoxRef?.hostView) !== -1 &&
			this.lastCommentBoxRef !== compRef
		) {
			this.lastCommentBoxViewRef?.detach(
				this.lastCommentBoxViewRef.indexOf(this.lastCommentBoxRef.hostView)
			);
		}

		this.lastCommentBoxViewRef = viewRef;
		this.lastCommentBoxRef = compRef;
	}

	// Emit custom event for nested CommentHistory ('cause Angular events doesn't bubble all the way to the top)
	emitLoadCommentReplies(rootComment: DNComment) {
		if (this.indentLevel < 1) this.loadCommentRepliesEmitter.emit(rootComment);
		else {
			const event: CustomEvent = new CustomEvent('LoadRepliesCustomEvent', {
				bubbles: true,
				detail: { data: rootComment }
			});

			this.elementRef.nativeElement.dispatchEvent(event);
		}
	}

	emitSubmitComment(createCommentDTO: CreateCommentDTO) {
		this.submitCommentEmitter.emit(createCommentDTO);
	}

	// Show COMMENTS_PER_LOAD comments more
	showMoreComments() {
		this.commentsShown += COMMENTS_PER_LOAD;
	}

	areThereMoreComments(): boolean {
		return this.commentsShown < this.totalComments.length;
	}

	commentTrackBy(index: number, comment: DNComment) {
		return comment.idContenuto;
	}

	getCommentReplies(comment: DNComment) {
		return this.store.select(selectCommentsByParentId(comment.idContenuto));
	}
}
