import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TemplateCt } from '../../../domain/template-ct/template-ct.model';
import { toolbarSlideIn } from '../../util/animations';
import { CreateCommentDTO } from '../comments.component';
import { CommentsService } from '../comments.service';
import { CommentUploadActionsComponent } from './comment-upload-actions/comment-upload-actions.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgStyle } from '@angular/common';

class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && control.touched && isSubmitted);
	}
}

interface CommentForm {
	commentText: FormControl<string | null>;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-comment-box',
    templateUrl: 'comment-box.component.html',
    styleUrls: ['comment-box.component.scss'],
    animations: [toolbarSlideIn],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgStyle, MatFormFieldModule, MatInputModule, TextFieldModule, MatButtonModule, MatIconModule, CommentUploadActionsComponent]
})
export class CommentBoxComponent implements OnInit, OnDestroy {
	@Input() labelText?: string = this.ts.instant('dottnet.field.label.comments');

	@Output() readonly submitCommentEmitter: EventEmitter<CreateCommentDTO> = new EventEmitter();

	@ViewChild('autosize') autosize: CdkTextareaAutosize;
	@ViewChild(CommentUploadActionsComponent) uploadActionsComponent!: CommentUploadActionsComponent;

	attachmentTemplate: TemplateCt = undefined;

	commentForm: FormGroup<CommentForm>;

	public get commentText() {
		return this.commentForm.get('commentText');
	}

	matcher = new MyErrorStateMatcher();

	// UI vars
	indentLevel: number = 0;
	errorMessage: string = this.ts.instant('dottnet.field.error.comments');
	isAttachmentMenuVisible: boolean = false;

	// Don't reset this field 'cause it is the latest one in the uploads chain
	dontResetMe = false;

	// Subs
	clearMediaSub: Subscription;

	constructor(
		private ts: TranslateService,
		private fb: FormBuilder,
		private commentsService: CommentsService
	) {}

	ngOnInit(): void {
		this.commentForm = this.fb.group<{ commentText: FormControl<string | null> }>({
			commentText: new FormControl<string | null>('', { validators: Validators.required })
		});

		this.clearMediaSub = this.commentsService.onResetAttachment().subscribe(() => {
			if (this.dontResetMe) this.dontResetMe = false;
			else {
				this.isAttachmentMenuVisible = false;
				this.uploadActionsComponent.resetUploadedMedia();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.clearMediaSub) this.clearMediaSub.unsubscribe();
	}

	emitSubmitComment() {
		if (this.commentForm.valid) {
			this.submitCommentEmitter.emit({
				commentText: this.commentText.value,
				attachmentTemplate: this.attachmentTemplate
			});
			this.commentText.setValue('');
			this.commentText.markAsUntouched();

			this.uploadActionsComponent.resetUploadedMedia();
			this.isAttachmentMenuVisible = false;
		}
	}

	emitTemporaryAttachment(file: File) {
		this.commentsService.uploadTemporaryAttachment(file);

		if (file) {
			this.dontResetMe = true;
			this.commentsService.resetAttachment();
		}
	}

	saveAttachmentType(template: TemplateCt) {
		this.attachmentTemplate = template;
	}

	showAttachmentMenu() {
		this.isAttachmentMenuVisible = !this.isAttachmentMenuVisible;
	}
}
