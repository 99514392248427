<div class="flex-col flex-justify-center flex-align-start comments-wrapper">
	<h3 class="dottnet-content-title">{{ commentSectionTitle }}</h3>
	<dottnet-comment-box
		[dottnetSigninDirective]="permissionDialogTitle"
		(submitCommentEmitter)="emitSubmitComment($event)"
	></dottnet-comment-box>
	<dottnet-comment-history
		[comments$]="comments$"
		(loadCommentRepliesEmitter)="emitLoadCommentReplies($event)"
		(submitCommentEmitter)="emitSubmitComment($event)"
	></dottnet-comment-history>
</div>
