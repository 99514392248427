import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../../../core/auth/auth.selectors';
import { openDialog } from '../../../dialog/dn-dialog.actions';
import { SigninDialogComponent } from '../../../dialog/signin-dialog/signin-dialog.component';
import { AuthLoginState } from './../../../../core/auth/auth.models';

@Directive({
    selector: '[dottnetSigninDirective]',
    standalone: true
})
export class SigninDirective implements OnDestroy {
	@Input('dottnetSigninDirective') title: string;
	subs: Subscription;

	@HostListener('click') onClick() {
		this.subs = this.store
			.select(selectAuthLoginState)
			.pipe(
				take(1),
				filter(
					(loginState) =>
						![AuthLoginState.LOGGEDHARD, AuthLoginState.LOGGEDSOFT].includes(loginState)
				),
				tap(() => {
					this.store.dispatch(
						openDialog({
							content: undefined,
							urlToDownload: undefined,
							urlWithContext: undefined,
							componentType: SigninDialogComponent,
							panelClass: 'dn-dialog-small',
							data: {
								dialogTitle: this.title
							}
						})
					);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		if (this.subs) this.subs.unsubscribe();
	}
	constructor(public store: Store) { }
}
