import {
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DNComment } from '../../../../domain/content/content.model';
import { WidgetInjectorDirective } from '../../../util/directives/widget-injector/widget-injector.directive';
import { CommentBoxComponent } from '../../comment-box/comment-box.component';
import { CreateCommentDTO } from '../../comments.component';
import { CommentsService } from '../../comments.service';
import { CommentHistoryComponent } from '../comment-history.component';
import { SigninDirective } from '../../../util/directives/signin/signing-directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

const MAX_NUM_REPLIES = 2;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-comment-bubble',
    templateUrl: 'comment-bubble.component.html',
    styleUrls: ['comment-bubble.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, TextFieldModule, NgIf, MatButtonModule, MatIconModule, SigninDirective, WidgetInjectorDirective, DatePipe]
})
export class CommentBubbleComponent implements OnInit, OnDestroy {
	// Current comment for this bubble
	@Input() comment: DNComment;
	// Current replies for this bubble
	@Input() replies$: Observable<DNComment[]> = new Observable();
	// Keep track of indentationLevel to make other children indent correctly
	@Input() indentLevel: number;
	// Manage if the bubble can show more comments/allowed to get replied
	@Input() areActionsEnabled: boolean = true;

	// Show the commentBox
	@Output() readonly showReplyBoxEmitter: EventEmitter<{
		viewRef: ViewContainerRef;
		compRef: ComponentRef<CommentBoxComponent>;
	}> = new EventEmitter();

	// Show history
	@Output() readonly loadHistoryEmitter: EventEmitter<DNComment> = new EventEmitter();
	// Submit comment
	@Output() readonly submitCommentEmitter: EventEmitter<CreateCommentDTO> = new EventEmitter();

	@ViewChild(WidgetInjectorDirective, { static: true }) injectionPoint!: WidgetInjectorDirective;

	// UI vars
	permissionDialogTitle: string = this.ts.instant('dottnet.permessi.commentPermission');

	maxNumReplies: number = MAX_NUM_REPLIES;
	showMoreLabel: string = '';
	replyLabel: string = this.ts.instant('dottnet.field.label.comments.reply');

	// Child components refs
	commentBoxRef: ComponentRef<CommentBoxComponent> = undefined;
	historyRef: ComponentRef<CommentHistoryComponent> = undefined;

	// Subs
	historyRefSubmitSub: Subscription;
	commentBoxRefSubmitSub: Subscription;

	constructor(
		private ts: TranslateService,
		private matDialog: MatDialog,
		private commentsService: CommentsService
	) {}

	ngOnInit(): void {
		this.showMoreLabel = this.ts.instant('dottnet.field.label.comments.showMore', {
			numCommenti: this.comment.numRisposte
		});
	}

	ngOnDestroy(): void {
		if (this.historyRefSubmitSub) this.historyRefSubmitSub.unsubscribe();
		if (this.commentBoxRefSubmitSub) this.commentBoxRefSubmitSub.unsubscribe();
	}

	// Create commentBox component if still undefined
	showCommentBox(comment: DNComment, indentLevel: number) {
		if (this.matDialog.openDialogs.length === 0) {
			// If this comment can use actions
			if (this.areActionsEnabled) {
				if (!this.commentBoxRef) {
					// Create commentBox
					this.commentBoxRef = this.injectionPoint.viewContainerRef.createComponent<CommentBoxComponent>(
						CommentBoxComponent
					);

					this.commentBoxRef.instance.labelText = `${this.ts.instant(
						'dottnet.field.label.comments.replying'
					)} ${comment.autore}`;
					this.commentBoxRef.instance.indentLevel = indentLevel + 1;

					// Subscribe to commentBox submitCommentEmitter
					this.commentBoxRefSubmitSub = this.commentBoxRef.instance.submitCommentEmitter.subscribe(
						({ commentText, attachmentTemplate }) => {
							// Detach commentBox
							this.injectionPoint.viewContainerRef.detach(
								this.injectionPoint.viewContainerRef.indexOf(this.commentBoxRef.hostView)
							);
							// Reset its value
							this.commentBoxRef.instance.commentText.setValue('');

							// Emit from this component emitter to notify outer histories/comments-component
							this.submitCommentEmitter.emit({
								commentText,
								attachmentTemplate,
								parentComment: this.comment
							});
							this.showHistory(this.comment);
						}
					);
				}

				// Insert commentBox at 0 position
				this.injectionPoint.viewContainerRef.insert(this.commentBoxRef.hostView, 0);

				// Communicate to parent to hide last commentBox
				this.showReplyBoxEmitter.emit({
					viewRef: this.injectionPoint.viewContainerRef,
					compRef: this.commentBoxRef
				});
			}
		}
	}

	// Show comment history component
	showHistory(comment: DNComment) {
		if (this.areActionsEnabled) {
			// Notify outer components to load comments tree
			this.loadHistoryEmitter.emit(comment);
			// If still undefined
			if (!this.historyRef) {
				// Create history component
				this.historyRef = this.injectionPoint.viewContainerRef.createComponent<CommentHistoryComponent>(
					CommentHistoryComponent
				);

				this.historyRef.instance.indentLevel = this.indentLevel + 1;
				this.historyRef.instance.comments$ = this.replies$;

				// Subscribe to internal eventEmitter to submit comments
				this.historyRefSubmitSub = this.historyRef.instance.submitCommentEmitter.subscribe(
					({ commentText, parentComment, attachmentTemplate }) =>
						this.submitCommentEmitter.emit({ commentText, attachmentTemplate, parentComment })
				);
			} else {
				this.injectionPoint.viewContainerRef.insert(this.historyRef.hostView);
			}
		}
	}

	showAttachment(comment: DNComment) {
		this.commentsService.showAttachment(comment.idContenutoAllegato, comment.templateAllegato);
	}

	// If historyComponent is not already shown and we have more than 0 answers to this comment
	canShowMore() {
		return (
			this.injectionPoint.viewContainerRef.indexOf(this.historyRef?.hostView) === -1 &&
			this.comment.numRisposte > 0
		);
	}

	// If commentBox is not already shown
	canReply() {
		return (
			this.injectionPoint.viewContainerRef.indexOf(this.commentBoxRef?.hostView) === -1 &&
			this.indentLevel < this.maxNumReplies
		);
	}
}
