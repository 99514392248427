import { getTemplateFromId } from './../../../domain/template-ct/template-ct.model';
import { combineLatestWith, tap } from 'rxjs/operators';
import { Location, NgClass, NgIf } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { EnvironmentService } from '../../../../environments/environment.service';
import { LogService } from '../../../core/log/log.service';
import { MetaService } from '../../../core/meta/meta.service';
import { Content, ContentVisualizationType } from '../../../domain/content/content.model';
import { TemplateTypeId } from '../../../domain/template-ct/template-ct.model';
import { selectUserId } from '../../../domain/user/user.selectors';
import {
	loadInteresting,
	loadLatestNews,
	loadMostRead,
	loadRelated
} from '../../../domain/widget/widget.actions';
import {
	selectWidgetInterestingContent,
	selectWidgetLatestNewsContent,
	selectWidgetMostReadContent,
	selectWidgetRelatedContent
} from '../../../domain/widget/widget.selectors';
import { ContentContainerComponent } from '../../content/content.component';
import { selectCurrentContent } from '../../../domain/content/content.selectors';
import { loadComments } from '../../../domain/content/content.actions';
import { NotificationService } from '../../../core/notifications/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { loadCurrentTopic, loadCurrentTopicSuccess } from '../../../domain/topic/topic.actions';
import { selectTopicByID } from '../../../domain/topic/topic.selector';
import { CommentsComponent } from '../../../shared/comments/comments.component';
import { SocialComponent } from '../../../shared/social/social.component';
import { ArticleViewerComponent } from '../../../shared/article-viewer/article-viewer.component';
import { ContentListViewerComponent } from '../../../shared/content-list-viewer/content-list-viewer.component';
import { MessageInPageComponent } from '../../../shared/message-in-page/message-in-page.component';
import { ContentViewComponent } from '../../../shared/content-view/content-view.component';
import { PollSmartComponent } from '../../poll-smart/poll-smart.component';
import { Poll,PollQuestionAnswer } from '../../../domain/poll-smart/poll-smart.model';
import { selectPoll } from '../../../domain/poll-smart/poll-smart.selectors';

@Component({
    selector: 'dottnet-topic',
    templateUrl: './topic.component.html',
    styleUrls: ['./topic.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass,NgIf, ContentViewComponent, MessageInPageComponent, ContentListViewerComponent, ArticleViewerComponent, SocialComponent, CommentsComponent, TranslateModule],
    providers: [PollSmartComponent]
})
export class TopicComponent
	extends ContentContainerComponent
	implements OnInit, OnDestroy, OnChanges
{
	//  Reference to access the enum in the template
	readonly ContentVisualizationType = ContentVisualizationType;
	readonly TemplateTypeId = TemplateTypeId;

	articleRelated$: Observable<Content[]> = new Observable();
	articleInteresting$: Observable<Content[]> = new Observable();
	articleLatestNews$: Observable<Content[]> = new Observable();
	articleMostRead$: Observable<Content[]> = new Observable();

	@Input() idTopic: number;
  showAdv: boolean;

  poll$: Observable<Poll> = new Observable();
  pollId: number;

	premiumUser: boolean;
	constructor(
		store: Store,
		actRoute: ActivatedRoute,
		envService: EnvironmentService,
		router: Router,
		logService: LogService,
		location: Location,
		private changeDetectorRef: ChangeDetectorRef,
		notificationService: NotificationService,
		metaService: MetaService,
		translate: TranslateService,
		changeDetector: ChangeDetectorRef,
    private pollClass: PollSmartComponent
	) {
		super(
			store,
			actRoute,
			envService,
			router,
			logService,
			location,
			TemplateTypeId.TOPIC,
			notificationService,
			metaService,
			translate,
			changeDetector
		);
	}

	ngOnInit(): void {
		super.init();

    this.poll$ = this.store.select(selectPoll);

		this.subList.push(
			this.store
				.select(selectTopicByID(this.idTopic))
				.pipe(filter((topic) => !!topic))
				.subscribe((topic) => {
          this.showAdv = topic.showAdv;
					// setto il CurrentTopic
					this.store.dispatch(loadCurrentTopicSuccess({ topic: topic }));
				})
		);

		this.articleInteresting$ = this.store.select(selectWidgetInterestingContent);
		this.articleRelated$ = this.store.select(selectWidgetRelatedContent);

		this.subList.push(
			this.contentError$
				.pipe(tap((error) => this.logService.info('content error article: ', error)))
				.subscribe((error) => {
					this.contentError = error;
					this.changeDetectorRef.detectChanges();
				})
		);

		// mi sottoscrivo allo userId per poter dispatchare le get dei widget
		this.subList.push(
			this.store
				.select(selectUserId)
				.pipe(
					filter((userId) => !!userId),
					combineLatestWith(
						this.store
							.select(selectCurrentContent)
							.pipe(filter((currentContent) => !!currentContent))
					)
				)
				.subscribe(([userId, currentContent]) => {

          this.userId = userId;
          this.pollId = currentContent.idPoll;
          // se esiste un poll associato, ne carico i dettagli
          if(this.pollId > 0){
            this.pollClass.getPoll(this.pollId,this.userId);
          }
					// Related
					const payloadLoadRelated = {
						category: 0,
						template: getTemplateFromId(TemplateTypeId.ARTICOLO).templateDescription,
						contentId: currentContent.idContenuto,
						userId: userId
					};
					this.store.dispatch(loadRelated(payloadLoadRelated));

					// Interesting
					const payloadLoadArticleInteresting = {
						userId: userId,
						category: currentContent.idTopic.toString(),
						contentId: currentContent.idContenuto
					};
					this.store.dispatch(loadInteresting(payloadLoadArticleInteresting));

					// Comments
					const payloadLoadComments = {
						commentId: currentContent.idContenuto,
						userId: userId,
						template: getTemplateFromId(TemplateTypeId.ARTICOLO)
					};
					this.store.dispatch(loadComments(payloadLoadComments));
				})
		);
	}

	ngOnChanges(): void {
		this.subList.push(
			this.store
				.select(selectTopicByID(this.idTopic))
				.pipe(filter((topic) => !!topic))
				.subscribe((topic) => {
					// setto il CurrentTopic
					this.store.dispatch(loadCurrentTopicSuccess({ topic: topic }));
				})
		);
	}

  submitPoll(PollQuestionAnswer: PollQuestionAnswer[]): void {
    this.pollClass.submitPoll(PollQuestionAnswer,this.userId,this.pollId);
  }

  undoPoll(): void {
    this.pollClass.undoPoll(this.userId,this.pollId);
  }

	ngOnDestroy(): void {
		super.destroy();
	}
}
