import { DOCUMENT, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Output, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import {
	getTemplateFromDescription,
	TemplateCt
} from '../../../../domain/template-ct/template-ct.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

const ACCEPTED_FILE_TYPES = '';
const ACCEPTED_VIDEO_TYPES = '.3gp, .mp4, .webm, .mov';
const ACCEPTED_IMAGE_TYPES = 'image/*';

// No changeDetection onPush cause it would update the uploadingContentName only if input vars change (no one here)
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
    selector: 'dottnet-comment-upload-actions',
    templateUrl: 'comment-upload-actions.component.html',
    styleUrls: ['comment-upload-actions.component.scss'],
    host: {
        style: 'margin-bottom: 1rem'
    },
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, TranslateModule]
})
export class CommentUploadActionsComponent {
	@Output() readonly attachmentTypeEmitter: EventEmitter<TemplateCt> = new EventEmitter();
	@Output() readonly temporaryAttachmentEmitter: EventEmitter<File> = new EventEmitter();

	requiredFileTypeFile: string = ACCEPTED_FILE_TYPES;
	requiredFileTypeVideo: string = ACCEPTED_VIDEO_TYPES;
	requiredFileTypeImage: string = ACCEPTED_IMAGE_TYPES;

	uploadingContentName: string = '';

	temporaryMediaNameSub: Subscription;

	constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

	onFileSelected(event: any) {
		const file: File = event.target.files[0];

		if (file) {
			this.uploadingContentName = file.name;

			this.temporaryAttachmentEmitter.emit(file);

			this.attachmentTypeEmitter.emit(getTemplateFromDescription('file'));
		}
	}

	onVideoSelected(event: any) {
		const file: File = event.target.files[0];

		if (file) {
			this.uploadingContentName = file.name;

			this.temporaryAttachmentEmitter.emit(file);

			this.attachmentTypeEmitter.emit(getTemplateFromDescription('video'));
		}
	}

	onImageSelected(event: any) {
		const file: File = event.target.files[0];

		if (file) {
			this.uploadingContentName = file.name;

			this.temporaryAttachmentEmitter.emit(file);

			this.attachmentTypeEmitter.emit(getTemplateFromDescription('gallery'));
		}
	}

	resetUploadedMedia() {
		this.uploadingContentName = '';
		this.resetFileInputs();
		this.attachmentTypeEmitter.emit(undefined);
		this.temporaryAttachmentEmitter.emit(undefined);
	}

	// Reset input fields to make possible uploading twice the same file consecutively
	resetFileInputs() {
		this.document
			.querySelectorAll('.file-input')
			.forEach((fileInput) => this.renderer.setProperty(fileInput, 'value', ''));
	}
}
