import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DNComment } from '../../domain/content/content.model';
import { TemplateCt } from '../../domain/template-ct/template-ct.model';
import { CommentsService } from './comments.service';
import { CommentHistoryComponent } from './comment-history/comment-history.component';
import { SigninDirective } from '../util/directives/signin/signing-directive';
import { CommentBoxComponent } from './comment-box/comment-box.component';
import { CommentBubbleComponent } from './comment-history/comment-bubble/comment-bubble.component';

export interface CreateCommentDTO {
	commentText: string;
	// attachment template if any
	attachmentTemplate?: TemplateCt;
	// comment being replied if any
	parentComment?: DNComment;
}

@Component({
    selector: 'dottnet-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommentBoxComponent, SigninDirective, CommentHistoryComponent, CommentBubbleComponent]
})
export class CommentsComponent implements OnInit, OnDestroy {
	// Custom event that bubbles from inside the component tree (CommentHistoryComponents nested more than 1 level deep)
	@HostListener('LoadRepliesCustomEvent', ['$event'])
	onCustomLoadRepliesEventCaptured(event: CustomEvent) {
		const comment: DNComment = event.detail.data;
		this.loadCommentRepliesEmitter.emit(comment);
	}

	@Input() comments$: Observable<DNComment[]> = new Observable();

	@Output() readonly submitCommentEmitter: EventEmitter<CreateCommentDTO> = new EventEmitter();
	@Output() readonly loadCommentRepliesEmitter: EventEmitter<DNComment> = new EventEmitter();
	@Output() readonly uploadTemporaryAttachmentEmitter: EventEmitter<File> = new EventEmitter();
	@Output() readonly showAttachmentEmitter: EventEmitter<{
		idAttachment: number;
		templateAttachment: string;
	}> = new EventEmitter();

	// UI vars
	commentSectionTitle: string = this.ts.instant('dottnet.title.comments');
	permissionDialogTitle: string = this.ts.instant('dottnet.permessi.commentPermission');

	// Sublist
	subList: Subscription[] = [];

	constructor(private ts: TranslateService, private commentsService: CommentsService) {}

	// Subscribe to commentsService subjects
	ngOnInit(): void {
		this.subList.push(
			this.commentsService
				.onUploadTemporaryAttachment()
				.subscribe((file) => this.emitUploadTemporaryAttachment(file)),
			this.commentsService
				.onShowAttachment()
				.subscribe(({ idAttachment, templateAttachment }) =>
					this.emitShowAttachment(idAttachment, templateAttachment)
				)
		);
	}

	ngOnDestroy(): void {
		this.subList.forEach((sub) => sub.unsubscribe());
	}

	emitLoadCommentReplies(comment: DNComment) {
		this.loadCommentRepliesEmitter.emit(comment);
	}

	emitSubmitComment(commentDTO: CreateCommentDTO) {
		this.submitCommentEmitter.emit(commentDTO);
	}

	emitUploadTemporaryAttachment(file: File) {
		this.uploadTemporaryAttachmentEmitter.emit(file);
	}

	emitShowAttachment(idAttachment: number, templateAttachment: string) {
		this.showAttachmentEmitter.emit({ idAttachment, templateAttachment });
	}
}
