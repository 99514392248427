import { trigger, transition, style, stagger, animate, query, state } from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [
	transition('* <=> *', [
		query(
			':enter',
			[style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
			{ optional: true }
		),
		query(
			':leave',
			[style({ opacity: 1 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 0 })))],
			{ optional: true }
		)
	])
]);

export const slideInAnimation = trigger('slideInAnimation', [
	state('in', style({ height: '*' })),
	transition(':leave', [style({ height: '*' }), animate(250, style({ height: 0 }))]),
	transition(':enter', [style({ height: 0 }), animate(250, style({ height: '*' }))])
]);

export const toolbarSlideIn = trigger('toolbarSlideIn', [
	state(
		'false',
		style({
			transform: 'translateY(-70px)',
			opacity: '0',
			height: '0',
			'pointer-events': 'none'
		})
	),
	state(
		'true',
		style({
			transform: 'translateY(0)',
			height: '50px',
			opacity: '1'
		})
	),
	transition('true <=> false', animate('400ms ease-in-out'))
]);
