<div class="input-actions">
	<!-- <input
		type="file"
		class="file-input"
		[accept]="requiredFileTypeFile"
		(change)="onFileSelected($event)"
		#fileUpload
	/> -->
	<input
		type="file"
		class="file-input"
		[accept]="requiredFileTypeVideo"
		(change)="onVideoSelected($event)"
		#videoUpload
	/>
	<input
		type="file"
		class="file-input"
		[accept]="requiredFileTypeImage"
		(change)="onImageSelected($event)"
		#imageUpload
	/>
</div>
<div id="actionsBox" class="full-width flex-row flex-justify-space-between flex-align-center">
	<h4 class="flex-row flex-align-center uploading-content-name">
		<button
			mat-icon-button
			type="button"
			*ngIf="uploadingContentName"
			(click)="resetUploadedMedia()"
		>
			<mat-icon class="cursor-pointer" color="accent">cancel</mat-icon>
		</button>
		{{ uploadingContentName || 'dottnet.field.label.comments.attachment' | translate }}
	</h4>
	<div class="action-buttons flex-row">
		<!-- <button mat-icon-button type="button" (click)="fileUpload.click()">
			<mat-icon class="cursor-pointer" color="accent">picture_as_pdf</mat-icon>
		</button> -->
		<button mat-icon-button type="button" (click)="videoUpload.click()">
			<mat-icon class="cursor-pointer" color="accent">movie</mat-icon>
		</button>
		<button mat-icon-button type="button" (click)="imageUpload.click()">
			<mat-icon class="cursor-pointer" color="accent">add_photo_alternate</mat-icon>
		</button>
	</div>
</div>
